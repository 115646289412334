<template>
  <div>
    <BAlert
      variant="danger"
      :show="hasLoadError"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No campaign found with this product id. Check
        <BLink
          class="alert-link"
          :to="{ name: 'commerce-product-list'}"
        >
          Product List
        </BLink>
        for other products.
      </div>
    </BAlert>
    <BRow>
      <BCol cols="9">
        <BTabs>
          <BTab
            title="상품"
            :active="tabStage === 'product'"
            @click="setTabStage('product')"
          >
            <ProductInfoCard
              :product="product"
              :options="options"
              :categoryOptions="categoryOptions"
            />
          </BTab>
          <BTab
            title="가격"
            :active="tabStage === 'price'"
            @click="setTabStage('price')"
          >
            <PriceInfoCard
              :product="product"
              :vat="vat"
            />
          </BTab>
          <BTab
            title="배송"
            :active="tabStage === 'delivery'"
            @click="setTabStage('delivery')"
          >
            <DeliveryInfoCard
              :product="product"
              :additionalShippingFeeCond="additionalShippingFeeCond"
              :shippingFreeCond="shippingFreeCond"
              :shippingPolicy="shippingPolicy"
            />
          </BTab>
          <BTab
            title="옵션"
            :active="tabStage === 'option'"
            @click="setTabStage('option')"
          >
            <OptionsInfoCard
              :options="options"
            />
          </BTab>
          <BTab
            title="기타"
            :active="tabStage === 'etc'"
            @click="setTabStage('etc')"
          >
            <AbroadInfoCard
              :product="product"
              :abroadJson="abroadJson"
            />
            <VendorInfoCard
              :vendor="vendor"
            />
          </BTab>
        </BTabs>
      </BCol>
      <BCol cols="3">
        <ActionCard
          :product="productUpdate"
          @turnOnMemo="turnOnMemo"
          @turnOnApproveModal="turnOnApproveModal"
          @turnOnRejectModal="turnOnRejectModal"
        />
        <TagbyTimeline
          title="타임라인"
          :timeline-data="timelines"
        />
      </BCol>
    </BRow>

    <TagbyMemo
      :visible="visibleMemo"
      :content="memoContent"
      :isSaving="isSavingMemo"
      @hidden="turnOffMemo"
      @input="inputMemoContent"
      @save="createMemo"
    />

    <ApproveModal
      :visible="visibleApproveModal"
      @ok="approve"
      @hidden="turnOffApproveModal"
    />

    <RejectModal
      :visible="visibleRejectModal"
      :feedback="memoContent"
      @inputFeedback="inputMemoContent"
      @ok="reject"
      @hidden="turnOffRejectModal"
    />
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import viewModel from './viewModel'
import TagbyTimeline from '@/components/TagbyTimeline.vue'
import TagbyMemo from '@/components/TagbyMemo.vue'
import ActionCard from './components/ActionCard.vue'
import ProductInfoCard from './components/ProductInfoCard.vue'
import PriceInfoCard from './components/PriceInfoCard.vue'
import DeliveryInfoCard from './components/DeliveryInfoCard.vue'
import OptionsInfoCard from './components/OptionsInfoCard.vue'
import AbroadInfoCard from './components/AbroadInfoCard.vue'
import VendorInfoCard from './components/VendorInfoCard.vue'
import ApproveModal from './components/ApproveModal.vue'
import RejectModal from './components/RejectModal.vue'

export default defineComponent({
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,

    TagbyTimeline,
    TagbyMemo,

    ActionCard,
    ProductInfoCard,
    PriceInfoCard,
    DeliveryInfoCard,
    OptionsInfoCard,
    AbroadInfoCard,
    VendorInfoCard,
    ApproveModal,
    RejectModal,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
})
</script>

<style>
.updated-field legend{
  color: var(--danger)!important;
}
</style>

import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'

import storeModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-product-update-detail'

  const { makeToast } = useToast()
  const storeDispatch = (name, params) => store.dispatch(`${APP_STORE_MODULE_NAME}/${name}`, params)

  const product = ref()
  const productUpdate = ref()
  const hasLoadError = ref(false)

  const additionalShippingFeeCond = ref({
    jeju: 0,
    island_mountain: 0,
  })
  const shippingFreeCond = ref({
    gte: null,
  })
  const vat = ref({
    is_tax_free: false,
  })
  const abroadJson = ref({
    nation: null,
    acceptor: null,
    contact: null,
  })
  const options = ref()
  const vendor = ref()
  const timelines = ref()
  const isUploadingFile = ref(false)
  const categoryOptions = ref([])
  const shippingPolicy = ref({})

  const tabStage = ref()
  const setTabStage = tab => {
    tabStage.value = tab
    router.replace({
      query: {
        tab: tabStage.value,
      },
    }).catch(() => { })
  }

  const fetchProductDetail = () => storeDispatch('fetchProductDetail', {
    idx: productUpdate.value.product_idx_id,
  }).then(response => {
    const resData = response.data.data
    product.value = resData.product
    vendor.value = resData.vendor
    const constAdditionalShippinFeeCond = product.value.additional_shipping_fee_cond ?? {}
    additionalShippingFeeCond.value = {
      jeju: constAdditionalShippinFeeCond.jeju ?? 0,
      island_mountain: constAdditionalShippinFeeCond.island_mountain ?? 0,
    }
    const constShippingFreeCond = product.value.shipping_free_cond ?? {}
    shippingFreeCond.value = {
      gte: constShippingFreeCond.gte,
    }
    vat.value = {
      is_tax_free: (product.value.vat_rate ?? 0) === 0,
    }
    abroadJson.value = {
      ...abroadJson.value,
      ...(product.value.abroad_json ?? {}),
    }
    options.value = resData.options.map(o => ({ ...o, state: 'ACTIVE' }))
    shippingPolicy.value = resData.shipping_policy ?? {}
  }).catch(() => {
    hasLoadError.value = true
  }).finally(() => {
  })

  const fetchDetail = () => storeDispatch('fetchDetail', {
    idx: router.currentRoute.params.idx,
  }).then(response => {
    productUpdate.value = response.data.data.product
    hasLoadError.value = false
    fetchProductDetail()
  }).catch(() => {
    hasLoadError.value = true
  }).finally(() => {
  })

  const fetchTimelines = () => {
    storeDispatch('fetchTimelines', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      timelines.value = response.data.data
    }).catch(() => {
      makeToast('danger', '타임라인을 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  const fetchCategories = () => {
    storeDispatch('fetchCategories')
      .then(response => {
        categoryOptions.value = response.data.data.map(c => ({ label: `${c.class_1} > ${c.class_2}`, value: c.idx }))
      }).catch(() => {
        makeToast('danger', '카테고리 목록을 가져오는데 실패했습니다')
      }).finally(() => {
      })
  }

  const memoContent = ref()
  const visibleMemo = ref(false)
  const turnOnMemo = () => {
    visibleMemo.value = true
    memoContent.value = null
  }
  const turnOffMemo = () => {
    visibleMemo.value = false
  }
  const isSavingMemo = ref(false)
  const inputMemoContent = value => {
    memoContent.value = value
  }
  const createMemo = () => {
    isSavingMemo.value = true
    storeDispatch('createMemo', {
      idx: router.currentRoute.params.idx,
      content: memoContent.value,
    }).then(() => {
      makeToast('primary', '메모 생성에 성공했습니다')
      fetchTimelines()
    }).catch(() => {
      makeToast('primary', '메모 생성에 성공했습니다')
    }).finally(() => {
      isSavingMemo.value = false
      turnOffMemo()
    })
  }

  const visibleApproveModal = ref(false)
  const turnOnApproveModal = () => {
    visibleApproveModal.value = true
  }
  const turnOffApproveModal = () => {
    visibleApproveModal.value = false
  }
  const approve = () => {
    storeDispatch('approve', {
      idx: router.currentRoute.params.idx,
    }).then(() => {
      makeToast('primary', '승인에 성공했습니다')
      fetchTimelines()
    }).catch(() => {
      makeToast('danger', '승인에 실패했습니다')
    }).finally(() => {
    })
  }

  const visibleRejectModal = ref(false)
  const turnOnRejectModal = () => {
    memoContent.value = null
    visibleRejectModal.value = true
  }
  const turnOffRejectModal = () => {
    visibleRejectModal.value = false
  }
  const reject = () => {
    storeDispatch('reject', {
      idx: router.currentRoute.params.idx,
      feedback: memoContent.value,
    }).then(() => {
      makeToast('primary', '거부에 성공했습니다')
      fetchTimelines()
    }).catch(() => {
      makeToast('danger', '거부에 실패했습니다')
    }).finally(() => {
    })
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchDetail()
    tabStage.value = router.history.current.query.tab
    if (!tabStage.value) {
      setTabStage('product')
    }
    fetchTimelines()
    fetchCategories()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    product,
    additionalShippingFeeCond,
    shippingFreeCond,
    vat,
    abroadJson,
    options,
    vendor,
    timelines,
    hasLoadError,
    isUploadingFile,
    tabStage,
    categoryOptions,
    shippingPolicy,

    setTabStage,

    memoContent,
    visibleMemo,
    turnOnMemo,
    turnOffMemo,
    isSavingMemo,
    inputMemoContent,
    createMemo,

    visibleApproveModal,
    turnOnApproveModal,
    turnOffApproveModal,
    approve,

    visibleRejectModal,
    turnOnRejectModal,
    turnOffRejectModal,
    reject,
    productUpdate,
  }
}
